.input-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    flex-basis: 265px;
    min-width: 120px;


    .inputLabel {
        font-family: SourceSansPro-Regular;
        font-size: 16px;
        line-height: 20px;
        color: var(--bodyText);
        margin-bottom: 4px;

        span {
            color: var(--red500);
        }
    }

    .inputItem {   
        margin-right: 8px;
        padding: 13px 40px;
        outline: none;
        font-family: SourceSansPro-Regular;
        font-size: 16px;
        color: var(--text2);

        &::placeholder {
            font-family: SourceSansPro-Regular;
            font-size: 16px;
            color: var(--text2);
        }
    }

    .error {
        border-color: var(--red500) !important;
    }

    textarea {
        padding: 10px 16px 24px 16px;
        height: 200px;
        resize: none;

        font-family: 'SourceSansPro-Regular', Arial, Helvetica, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
                
        margin-right: 0;
        border: 1px solid var(--grey);
        border-radius: 4px;
        box-sizing: border-box;

        &:focus {
            outline: none;
        }
    }

    .message {
        font-family: SourceSansPro-Regular;
        font-size: 16px;
        line-height: 24px;
        margin-top: 4px;
    }

    p.error {
        color: var(--red500);
    }

    .location_input,
    .keyword_input {
        content: "";
        position: absolute;
        left: 10px;
        top: 34px;
        bottom: 0;
        width: 20px;
        background-repeat: no-repeat;
    }

    .location_input {
        background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clip-rule='evenodd' d='M3.05493 8.61113C3.05493 4.77582 6.16407 1.66669 9.99938 1.66669C13.8347 1.66669 16.9438 4.77582 16.9438 8.61113C16.9438 10.9525 15.6922 13.2472 13.6285 15.4255C12.9209 16.1725 12.1648 16.8529 11.4085 17.4552C11.276 17.5607 11.148 17.6601 11.0254 17.7532L10.6745 18.0133L10.3846 18.2167C10.1513 18.3722 9.84743 18.3722 9.61417 18.2167L9.32423 18.0133L8.97333 17.7532C8.85074 17.6601 8.72279 17.5607 8.59023 17.4552C7.83392 16.8529 7.07785 16.1725 6.37024 15.4255C4.3066 13.2472 3.05493 10.9525 3.05493 8.61113ZM15.5544 8.6114C15.5544 5.54315 13.0671 3.05585 9.99883 3.05585C6.93058 3.05585 4.44328 5.54315 4.44328 8.6114C4.44328 10.5235 5.53536 12.5256 7.37797 14.4706C8.03755 15.1668 8.74632 15.8047 9.45486 16.3689C9.57865 16.4675 9.69789 16.5602 9.81181 16.6467L9.99883 16.7864L10.3606 16.5123L10.5428 16.3689C11.2513 15.8047 11.9601 15.1668 12.6197 14.4706C14.4623 12.5256 15.5544 10.5235 15.5544 8.6114Z' fill='%23A8A5A4'/%3e%3cpath fillRule='evenodd' clip-rule='evenodd' d='M7.22168 8.61109C7.22168 7.07697 8.46533 5.83331 9.99946 5.83331C11.5336 5.83331 12.7772 7.07697 12.7772 8.61109C12.7772 10.1452 11.5336 11.3889 9.99946 11.3889C8.46533 11.3889 7.22168 10.1452 7.22168 8.61109ZM11.3878 8.61136C11.3878 7.8443 10.766 7.22247 9.99892 7.22247C9.23185 7.22247 8.61003 7.8443 8.61003 8.61136C8.61003 9.37842 9.23185 10.0003 9.99892 10.0003C10.766 10.0003 11.3878 9.37842 11.3878 8.61136Z' fill='%23A8A5A4'/%3e%3c/svg%3e");          
    }

    .keyword_input {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.71822 18H13.2818C16.58 18 17.1707 16.6919 17.343 15.0994L17.9583 8.59938C18.1798 6.61688 17.6055 5 14.1022 5H5.89777C2.39447 5 1.82016 6.61688 2.04168 8.59938L2.65702 15.0994C2.82931 16.6919 3.42003 18 6.71822 18Z' stroke='%23A8A5A4' strokeWidth='1.375' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M7 5V4.4C7 3.0725 7 2 9.8 2H11.2C14 2 14 3.0725 14 4.4V5' stroke='%23A8A5A4' strokeWidth='1.375' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M11 10.75V11.5C11 11.5075 11 11.5075 11 11.515C11 12.3325 10.9925 13 9.5 13C8.015 13 8 12.34 8 11.5225V10.75C8 10 8 10 8.75 10H10.25C11 10 11 10 11 10.75Z' stroke='%23A8A5A4' strokeWidth='1.375' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M18 9C16.1882 10.6689 14.1176 11.6623 12 12' stroke='%23A8A5A4' strokeWidth='1.375' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M2 9C3.82927 10.1159 5.89431 10.7899 8 11' stroke='%23A8A5A4' strokeWidth='1.375' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");    
    }

    .txtCounter {
        position: absolute;
        top: 11.1em;
        right: 1.3em;

        span {
            color: var(--red500);
        }
    }

    .inputItem::-webkit-outer-spin-button,
    .inputItem::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .inputItem[type=number] {
        -moz-appearance: textfield;
    }
}